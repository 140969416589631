import gsap from 'gsap'
import { useEffect, useState } from 'react'
import Entering1 from '../image/Entering/Entering1.png'
import Entering2 from '../image/Entering/Entering2.png'
import Entering3 from '../image/Entering/Entering3.png'
import Entering4 from '../image/Entering/Entering4.png'
import Entering5 from '../image/Entering/Entering5.png'
import Entering6 from '../image/Entering/Entering6.png'
import Entering7 from '../image/Entering/Entering7.png'
import Entering8 from '../image/Entering/Entering8.png'

const Entering = ({ setBefore }) => {
  const [randomArray, setRandomArray] = useState([])

  useEffect(() => {
    if (randomArray.length > 0) {
      const colSpans = gsap.utils.toArray('.col-span-1')
      gsap
        .to(colSpans, {
          duration: 0,
          opacity: 1,
          stagger: {
            amount: 0.5,
            from: 'random'
          }
        })
        .then(async () => {
          await gsap.to('.col-span-1', {
            display: 'none'
          })
          setBefore(false)
        })
    }
  }, [randomArray])

  useEffect(() => {
    const randomEntering = [
      Entering1,
      Entering2,
      Entering3,
      Entering4,
      Entering5,
      Entering6,
      Entering7,
      Entering8
    ]
    const randomArray = []
    const imgWidth = 160
    const imgHeight = 223
    const cols = window.innerWidth > 768 ? 12 : 6
    const width = Math.floor(window.innerWidth / imgWidth)
    const height = Math.floor(window.innerHeight / imgHeight)
    const row = window.innerWidth / width
    const col = window.innerHeight / height
    const total = Math.floor(((row / cols) + (col / cols)) * 5)
    for (let i = 0; i < total; i++) {
      const random = Math.floor(Math.random() * randomEntering.length)
      randomArray.push(randomEntering[random])
    }
    setRandomArray(randomArray)
  }, [])

  return (
    <>
      <div className='grid grid-cols-6 md:grid-cols-12 gap-0'>
        {
          randomArray.map((item, index) => (
            <img
              key={index}
              className='col-span-1 w-full h-full object-cover opacity-0'
              src={item}
              alt={`mosaic-${index}`}
            />
          ))
        }
      </div>
    </>
  )
}

export default Entering
