import { useContext } from 'react'
import { Context } from '../context'
import passBlack from '../image/Pass_Black.mp4'

const PopUpModal = () => {
  const { popUp, togglePopUp } = useContext(Context)

  return (
    <div className={`modal fixed w-full h-full top-0 left-0 flex items-center justify-center ${popUp.open ? 'opacity-100 block' : 'opacity-0 hidden'}`} style={{ zIndex: 100 }}>
      <div className="modal-overlay absolute w-full h-full bg-black -z-10" style={{ opacity: 0.85 }}></div>
      <div className="modal-container fixed w-full h-full overflow-y-auto ">
        <div className="modal-close bg-primary hover:bg-cyan-500 absolute top-5 right-5 cursor-pointer flex flex-col items-center text-black/80 text-sm" onClick={togglePopUp}>
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.75 18.75L41.25 41.25" stroke="black" strokeWidth="5"/>
          <path d="M41.25 18.75L18.75 41.25" stroke="black" strokeWidth="5"/>
          </svg>
        </div>
        <div className='w-full h-full flex items-center justify-center px-6'>
          <video style={{ maxWidth: '600px' }} className="w-full" controls src={passBlack}></video>
        </div>
      </div>
    </div>
  )
}
export default PopUpModal
