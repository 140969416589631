import EnteringBlue from '../../image/Entering/Entering1.png'
import EnteringBlack from '../../image/Entering/Entering2.png'

const Entering = () => {
  return (
    <>
      <div>
        <div id="scroll-element-entering" className=' w-0 h-0 opacity-0'></div>
        <div id="mobileEnteringTarget" className='h-screen overflow-hidden flex justify-center items-center'>
          <div id="entering-body" className='relative w-[200px] h-[200px]'>
            <img src={ EnteringBlue } alt="EnteringBlack-1" className='Entering-1 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[147px] z-[1]'/>
            <img src={ EnteringBlack } alt="EnteringBlack-2" className='Entering-2 absolute top-[-21%] left-[-30%] w-[94.34px]'/>
            <img src={ EnteringBlack } alt="EnteringBlack-3" className='Entering-3 absolute bottom-[-15%] left-[-24%] w-[67.13px]'/>
            <img src={ EnteringBlue } alt="EnteringBlack-4" className='Entering-4 absolute right-[-34%] bottom-[20%] w-[59.61px] z-[1]'/>

            <img src={ EnteringBlue } alt="EnteringBlue-1" className='Entering-5 absolute top-[25%] left-[-8%] w-[49.19px] z-[1]'/>
            <img src={ EnteringBlack } alt="EnteringBlue-2" className='Entering-6 absolute bottom-[-27%] right-[0%] w-[67.71px] z-[1]'/>
            <img src={ EnteringBlack} alt="EnteringBlue-3" className='Entering-7 absolute top-[-19%] right-[-25%] w-[98.91px]'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Entering
