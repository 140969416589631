function randRange (min, max) {
  return Math.random() * (max - min) + min
}
export function mapRange (value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1)
}

export const IS_HIGH_RES = window.matchMedia(`
  (-webkit-min-device-pixel-ratio: 2),
  (min--moz-device-pixel-ratio: 2),
  (-moz-min-device-pixel-ratio: 2),
  (-o-min-device-pixel-ratio: 2/1),
  (min-device-pixel-ratio: 2),
  (min-resolution: 192dpi),
  (min-resolution: 2dppx)
`)
export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
const IS_HIGH_RES_AND_MOBILE = (IS_HIGH_RES.matches && IS_MOBILE)

export let handlePointer = null

class Star {
  constructor (container) {
    const [size, depth] = container
    this.FORWARD_SPEED = 100
    this.SIDEWAYS_SPEED = 100
    if (IS_HIGH_RES_AND_MOBILE) {
      this.FORWARD_SPEED *= 2
      this.SIDEWAYS_SPEED *= 2
    }
    this.container = container
    this.x = randRange(-size, size)
    this.y = randRange(-size, size)
    this.z = randRange(0, depth)
    this.px = this.x
    this.py = this.y
    this.pz = this.z
    // this.color = `rgb(${randRange(110, 200)},${randRange(110, 240)},${randRange(230, 255)})`
    this.color = 'rgb(255,255,255)'
  }

  resetX () {
    const [size, _] = this.container
    this.x = randRange(-size, size)
    this.px = this.x
  }

  resetY () {
    const [size, _] = this.container
    this.y = randRange(-size, size)
    this.py = this.y
  }

  resetZ () {
    const [_, depth] = this.container
    this.z = randRange(0, depth)
    this.pz = this.z
  }

  update (deltaTime, container, xSpeed, zSpeed) {
    this.container = container
    const [size, depth] = container
    const sizeAndAQuarter = size + size / 4
    const depthMinusAQuarter = depth - depth / 4
    let defaultSpeed = this.FORWARD_SPEED
    let defaultSideSpeed = this.SIDEWAYS_SPEED
    if (zSpeed > 0) {
      const slowBy = mapRange(this.z, 0, depth, 1, 0.01)
      defaultSpeed *= slowBy
    } else if (zSpeed < 0) {
      const slowBy = mapRange(this.z, 0, depth, 1, 0.1)
      defaultSpeed *= slowBy
    }
    if (Math.abs(xSpeed) > 0) {
      const slowBy = mapRange(this.z, 0, size, 0.3, 0.4)
      defaultSideSpeed *= slowBy
    }
    this.z -= (defaultSpeed * zSpeed * deltaTime)
    this.x -= defaultSideSpeed * xSpeed * deltaTime
    const fuzzyDepth = randRange(depth, depthMinusAQuarter)
    const fuzzySize = randRange(size, sizeAndAQuarter)
    if (this.z < 1) {
      this.z = fuzzyDepth
      this.pz = this.z
      this.resetX()
      this.resetY()
    } else if (this.z > depth) {
      this.z = 0
      this.pz = this.z
      this.resetX()
      this.resetY()
    } else if (this.x < -fuzzySize) {
      this.x = size
      this.px = this.x
      this.resetY()
      this.resetZ()
    } else if (this.x > fuzzySize) {
      this.x = -size
      this.px = this.x
      this.resetY()
      this.resetZ()
    } else if (this.y < -fuzzySize) {
      this.y = size
      this.py = this.y
      this.resetX()
      this.resetZ()
    } else if (this.y > fuzzySize) {
      this.y = -size
      this.py = this.y
      this.resetX()
      this.resetZ()
    }
  }

  draw (context, container, screen, mouseX, mouseY) {
    const [width, height] = screen
    const [size, depth] = container
    const sx = mapRange(this.x / this.z, 0, 1, 0, width)
    const sy = mapRange(this.y / this.z, 0, 1, 0, height)
    const px = mapRange(this.px / this.pz, 0, 1, 0, width)
    const py = mapRange(this.py / this.pz, 0, 1, 0, height)
    const maxRadius = (IS_HIGH_RES.matches && IS_MOBILE) ? 4 : 2
    const radius = Math.min(Math.abs(mapRange(this.z, 0, depth, maxRadius, 0.01)), maxRadius)
    // star point
    context.beginPath()
    context.arc(sx, sy, radius, 0, 2 * Math.PI)
    context.fillStyle = this.color
    context.fill()
    this.px = this.x
    this.py = this.y
    this.pz = this.z
    // star trail
    context.beginPath()
    context.moveTo(px, py)
    context.lineTo(sx, sy)
    context.lineWidth = radius
    context.strokeStyle = this.color
    context.stroke()
  }
}

export const getPointerInput = (callback, delay = 600) => {
  callback = callback || (() => {
    console.error('PointerInput is missing a callback as the first argument')
  })
  const pointer = {
    x: 0,
    y: 0
  }
  let timer = false
  let animFrame = false
  handlePointer = (event) => {
    if (animFrame) {
      animFrame = window.cancelAnimationFrame(animFrame)
    }
    animFrame = window.requestAnimationFrame(() => {
      pointer.x = event.clientX
      pointer.y = event.clientY

      callback(pointer)
      if (timer) {
        timer = clearTimeout(timer)
      }
      timer = setTimeout(() => {
        callback(pointer)
      }, delay)
    })
  }

  return false
}
export class StarField {
  constructor (howManyStars, canvas, depth = 2, UIFadeDelay = 1) {
    this.canvas = canvas
    this.context = canvas.getContext('2d')
    this.resizeTimer = false
    this.isResizing = false
    this.wasResizing = false
    this.containerDepth = depth
    this.setCanvasSize()
    this.howManyStars = howManyStars
    this.stars = new Array(howManyStars)
    this.populateStarField()
    this.prevTime = 0
    this.deltaTime = 0.1
    this.xSpeed = 0
    this.zSpeed = 1
    this.UIFadeDelay = UIFadeDelay
    const handlePointer = (pointer) => {
      const [width, height] = this.screen
      this.zSpeed = mapRange(pointer.y, 0, height, 12, -4)
      this.xSpeed = mapRange(pointer.x, 0, width, -10, 10)
      if (Math.abs(this.xSpeed) > 2) {
        this.zSpeed /= (Math.abs(this.xSpeed) / 2)
      }
    }
    getPointerInput(handlePointer)
    this.showMouseControls = true
    this.pauseAnimation = false
    this.render()

    window.addEventListener('resize', () => this.handleResize(), true)
    window.addEventListener('beforeunload', () => this.rePopulateStarField())
  }

  startRenderLoop () {
    const renderLoop = (timestamp) => {
      timestamp *= 0.001
      this.deltaTime = timestamp - this.prevTime
      this.prevTime = timestamp
      if (!this.pauseAnimation) {
        this.clearCanvas()
        this.render()
      }
      window.requestAnimationFrame(renderLoop)
    }
    window.requestAnimationFrame(renderLoop)
  }

  pause () {
    this.pauseAnimation = true
  }

  play () {
    this.pauseAnimation = false
  }

  setCanvasSize () {
    this.canvas.width = this.canvas.parentElement.offsetWidth
    this.canvas.height = this.canvas.parentElement.offsetHeight
    const width = this.canvas.offsetWidth
    const height = this.canvas.offsetHeight
    const size = Math.max(width, height)
    const depth = size * this.containerDepth
    const screen = [width, height]
    const container = [size, depth]
    this.container = container
    this.screen = screen
    this.context.translate(width / 2, height / 2)
  }

  populateStarField () {
    for (let i = 0; i < this.stars.length; i++) {
      this.stars[i] = new Star(this.container)
    }
  }

  emptyStarField () {
    this.stars = new Array(this.howManyStars)
  }

  rePopulateStarField () {
    this.emptyStarField()
    this.populateStarField()
    return null
  }

  clearCanvas () {
    const [size, depth] = this.container
    this.context.clearRect(-size / 2, -size / 2, size, size)
  }

  render () {
    for (let i = 0; i < this.stars.length; i++) {
      if (!this.pauseAnimation) {
        this.stars[i].update(this.deltaTime, this.container, this.xSpeed, this.zSpeed)
      }
      this.stars[i].draw(this.context, this.container, this.screen, this.mouseX, this.mouseY)
    }
  }

  rePopOnResizeStop () {
    if (!this.isResizing && this.wasResizing) {
      this.rePopulateStarField()
    }
  }

  handleResize () {
    this.pause()
    // if a resizing timer exists already clear it out
    if (this.resizeTimer) {
      this.resizeTimer = clearTimeout(this.resizeTimer)
    }
    this.wasResizing = this.isResizing
    if (!this.isResizing) {
      this.isResizing = true
    }
    this.rePopOnResizeStop()
    if (this.pauseAnimation) {
      window.requestAnimationFrame(() => {
        this.setCanvasSize()
        this.render()
      })
    }
    this.resizeTimer = setTimeout(() => {
      this.wasResizing = this.isResizing
      this.isResizing = false
      this.rePopOnResizeStop()
      this.setCanvasSize()
      this.play()
    }, 200)
  }
}
