import Rec from '../image/rec.svg'
import RecSm from '../image/rec-sm.svg'
import RecPrimary from '../image/recPrimary.svg'
import RecPrimarySm from '../image/recPrimary-sm.svg'

const ImageComponent = ({ type }) => {
  return <picture>
    <source media="(max-width: 769px)" srcSet={type === 'primary' ? RecPrimarySm : RecSm} />
    <img src={type === 'primary' ? RecPrimary : Rec} alt="rec" />
  </picture>
}
export const Marquee = ({ classes, type, text }) => {
  return (
    <div className="marqueeTarget w-100 overflow-hidden relative z-30">
      <div className={`font-HalvarEngschrift scroll py-[26px] md:py-[38px] ${classes}`}>
        <div className="text">
          <div className='flex marquee-text'>
            {[...new Array(8)].map((_, i) => <span key={`text1_${i}`} className='flex items-center'><span className='px-4'>FIRSTCLASS {text}{' '} </span><ImageComponent type={type} /><ImageComponent type={type} /></span>)}
          </div>
        </div>
      </div>
    </div>
  )
}
export const MarqueeP = () => {
  return (
    <div className="w-100 overflow-hidden h-20 lg:h-[140px] z-30">
      <div className="font-HalvarEngschrift font-bold scroll  py-[38px]">
        <div className="text animated">
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS //
        </div>
        <div className="text animated">
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS //
        </div>
      </div>
    </div>
  )
}

export const MarqueeB = () => {
  return (
    <div className="w-100 overflow-hidden relative h-20 lg:h-[140px] z-30">
      <div className="font-HalvarEngschrift scroll bg-support text-primary py-[26px] lg:py-[38px] border-y border-primary">
        <div className="text animated">
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS //
        </div>
        <div className="text animated">
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS // FIRSTCLASS PROJECTS //
          FIRSTCLASS PROJECTS //
        </div>
      </div>
    </div>
  )
}
