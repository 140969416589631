import React from 'react'
import line from '../../image/line.svg'

export default function HomeAboutUs () {
  return (
    <>
      <div className="relative">
        <div id="MobileHomeAboutUsBody" className="overflow-hidden">
          <div
            className="about-scroll-content py-36 custom-px-13p md:pr-16 bg-white h-screen">
            <div>
              <h1
                data-aos="fade-up"
                data-aos-duration="400"
                className="font-HalvarEngschrift md:mb-3 lg:text-[80px] xl:text-[110px]"
              >
                FIRSTCLASS
                <br />
                LABS
              </h1>
              <div
                data-aos="fade-up"
                data-aos-duration="800"
                className="flex gap-2 items-start lg:text-2xl mt-4"
              >
                <img src={line} alt="line" />
                <p className="font-HalvarMittelschrift lg:leading-9 max-w-sm md:max-w-md pt-4 pl-2">
                  Firstclass Labs is a collective of unique individuals pushing
                  the boundary of technology and culture, shaping a better future
                  of humankind. We welcome true collectors, those with unique eyes
                  and appreciation for unique blockchain projects.
                </p>
              </div>
            </div>
          </div>
          <div
            className="card-scroll about-scroll-content h-screen">
            <div className="card-scroll-content h-full first chid custom-px-13p mx-5">
              <h3 data-aos="fade-up" data-aos-duration="400" className="card-scroll-content-title font-HalvarEngschrift md:text-5xl text-3xl">
                We are First.{' '}
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="600"
                className="card-scroll-content-txt max-w-lg md:text-2xl text-base font-HalvarMittelschrift"
              >
                We seek and create
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}true originality{' '}
                </span>
                , only to continuously reinvent and creatively shape Web3
                zeitgeist.
              </p>
            </div>
          </div>
          <div
            className="card-scroll   about-scroll-content h-screen" >
            <div className="chid card-scroll-content h-full custom-px-13p mx-5">
              <h3 data-aos="fade-up" data-aos-duration="400" className="card-scroll-content-title font-HalvarEngschrift md:text-5xl text-3xl">
                We are Us.{' '}
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="card-scroll-content-txt max-w-lg md:text-2xl text-base font-HalvarMittelschrift"
              >
                We believe in
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}collective wisdom.{' '}
                </span>
                We respect and value opinions of all form, from members of
                different backgrounds. A
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}close-knit community{' '}
                </span>
                dominates our digital DNA.
              </p>
            </div>
          </div>

          <div
            className="card-scroll   about-scroll-content h-screen ">
            <div className="chid card-scroll-content h-full custom-px-13p mx-5">
              <h3 data-aos="fade-up" data-aos-duration="800" className="card-scroll-content-title font-HalvarEngschrift md:text-5xl text-3xl">
                We are Thinkers.
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="card-scroll-content-txt max-w-lg md:text-2xl text-base font-HalvarMittelschrift"
              >
                We value thought- provoking ideas. We‘ll challenge the status quo
                and we’re
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}not afraid of failures.{' '}
                </span>
                Some of us will come up with innovative ideas; some of us will
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}build noteworthy projects.{' '}
                </span>
                But all of us will become more extraordinary than ever.
              </p>
            </div>
          </div>

          <div
            className="card-scroll   about-scroll-content h-screen ">
            <div className="chid card-scroll-content h-full custom-px-13p mx-5">
              <h3 data-aos="fade-up" data-aos-duration="900" className="card-scroll-content-title font-HalvarEngschrift md:text-5xl text-3xl">
                We believe in Transparency.
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="card-scroll-content-txt max-w-lg md:text-2xl text-base font-HalvarMittelschrift"
              >
                We believe in open and transparent governance. We treasure the
                trust in the
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}human relationships.{' '}
                </span>
              </p>
            </div>
          </div>

          <div
            className="card-scroll about-scroll-content h-screen">
            <div className="chid card-scroll-content h-full custom-px-13p mx-5 ">
              <h3 data-aos="fade-up" data-aos-duration="1000" className="card-scroll-content-title font-HalvarEngschrift md:text-5xl text-3xl">
                We connect the Dots.
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="card-scroll-content-txt max-w-lg md:text-2xl text-base font-HalvarMittelschrift"
              >
                We are the bridge between
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}technology and culture,{' '}
                </span>
                we bring on-chain data to off-chain real life. We straddle between
                Web2 and Web3 worlds
              </p>
            </div>
          </div>
          <div
            className="card-scroll   about-scroll-content h-screen ">
            <div className="chid card-scroll-content h-full custom-px-13p mx-5 w-full">
              <h3 data-aos="fade-up" data-aos-duration="1000" className="card-scroll-content-title font-HalvarEngschrift md:text-5xl text-3xl">
                We believe our work <br />must stay True <br />to our name.
              </h3>
              <p
                data-aos="fade-up"
                data-aos-duration="800"
                className="card-scroll-content-txt max-w-lg md:text-2xl text-base font-HalvarMittelschrift"
              >
                That is,
                <span
                  data-aos="card-scroll-content-txtHover"
                  data-aos-duration="1000"
                  className="card-scroll-content-txtHover"
                >
                  {' '}First Class{' '}
                </span>
                work.
              </p>
            </div>
          </div>
          <div
            className="about-scroll-content flex h-screen items-end">
            <div className="chid card-scroll-content h-full px-6 justify-center w-full">
              <h3 className="mb-0 card-scroll-content-title w-full text-left font-HalvarEngschrift md:text-5xl text-3xl">
                We are First.{' '}
              </h3>
              <h3 className="mb-0 card-scroll-content-title w-full text-left font-HalvarEngschrift md:text-5xl text-3xl">
                We are Us.{' '}
              </h3>
              <h3 className="mb-0 card-scroll-content-title w-full text-left font-HalvarEngschrift md:text-5xl text-3xl">
                We are Thinkers.
              </h3>
              <h3 className="mb-0 card-scroll-content-title w-full text-left font-HalvarEngschrift md:text-5xl text-3xl">
                We believe in Transparency.
              </h3>
              <h3 className="mb-0 card-scroll-content-title w-full text-left font-HalvarEngschrift md:text-5xl text-3xl">
                We connect the Dots.
              </h3>
              <h3 className="mb-0 card-scroll-content-title w-full text-left font-HalvarEngschrift md:text-5xl text-3xl">
                We believe our work must stay True to our name.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
