import Entering from './Entering'
import TableEntering from './TableEntering'
import MobileEntering from './MobileEntering'

export default function index () {
  const responsive = () => {
    const width = window.innerWidth
    if (width >= 1024) {
      return <Entering />
    }

    if (width > 576 && width < 1023) {
      return <TableEntering />
    }

    if (width <= 576) {
      return <MobileEntering />
    }
  }

  return (
    <>
      { responsive() }
    </>
  )
}
