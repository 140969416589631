
import EnteringBlue from '../../image/Entering/Entering1.png'
import EnteringBlack from '../../image/Entering/Entering2.png'

const HomeAboutEntering = () => {
  return (
    <>
      <div id="HomeAboutEnteringTarget">
        <div className='w-[200px] h-[200px] relative'>
          <img
            className='About-EnteringBlack-item--1 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[164px] z-[1]'
            src={EnteringBlue}
            alt="EnteringBlack-item--1"
          />
          <img
            className='About-EnteringBlack-item--2 absolute left-[-40%] top-[-13%] w-[106px]'
            src={EnteringBlack}
            alt="EnteringBlack-item--2"
          />
          <img
            className='About-EnteringBlack-item--3 absolute bottom-[0%] left-[-24%] w-[75px]'
            src={EnteringBlack}
            alt="EnteringBlack-item--3"
          />
          <img
            className='About-EnteringBlack-item--4 absolute right-[-30%] bottom-[19%] w-[66px] z-[6]'
            src={EnteringBlue}
            alt="EnteringBlack-item--4"
          />

          <img
            className='About-EnteringBlue-item--1 absolute top-[-30%] right-[-20%] w-[110px]'
            src={EnteringBlack}
            alt="EnteringBlue-item--1"
          />
          <img
            className='About-EnteringBlue-item--2 absolute right-[-10%] bottom-[-30%] w-[76px] z-[7]'
            src={EnteringBlack}
            alt="EnteringBlue-item--2"
          />
          <img
            className='About-EnteringBlue-item--3 absolute left-[-6%] top-[22%] w-[55px] z-[7]'
            src={EnteringBlue}
            alt="EnteringBlue-item--3"
          />
        </div>
      </div>
    </>
  )
}

export default HomeAboutEntering
