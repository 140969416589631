import { BtnPrimary } from './Button'
import Modal from './Modal'
import { useCallback } from 'react'
import { useModal } from '../context'

export function WalletButton () {
  const { showModal, hideModal } = useModal()

  const show = useCallback(() => {
    // showModal(
    //   <Modal isOpen={true} onDismiss={hideModal}>
    //     <p className='font-HalvarEngschrift text-3xl'>PLACEHOLDER</p>
    //   </Modal>
    // )

    const newWindow = window.open('https://pass.firstclass.wtf', 'Firstclass Pass', 'width=600,height=900')
    newWindow.focus()
  }, [hideModal, showModal])

  return (
    <>
      <BtnPrimary onClick={show}>CONNECT WALLET</BtnPrimary>
    </>
  )
}
