import React, { useLayoutEffect, useRef, useCallback } from 'react'
import { gsap, Expo } from 'gsap'
import 'aos/dist/aos.css'
// import { Header } from './components/Header'
import LoadingPage from './pages/LoadingPage'
import PopUpModal from './components/PopUpModal'
import { ContextProvider } from './context'

function App () {
  // function useTicker (callback, paused) {
  //   useLayoutEffect(() => {
  //     if (!paused && callback) {
  //       gsap.ticker.add(callback)
  //     }
  //     return () => {
  //       gsap.ticker.remove(callback)
  //     }
  //   }, [callback, paused])
  // }

  // const EMPTY = {}
  // function useInstance (value = {}) {
  //   const ref = useRef(EMPTY)
  //   if (ref.current === EMPTY) {
  //     ref.current = typeof value === 'function' ? value() : value
  //   }
  //   return ref.current
  // }

  // function getScale (diffX, diffY) {
  //   const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2))
  //   return Math.min(distance / 300, 0.35)
  // }

  // function getAngle (diffX, diffY) {
  //   return (Math.atan2(diffY, diffX) * 180) / Math.PI
  // }

  // const GooeyCursor = () => {
  //   const cursorRef = useRef(null)

  //   const pos = useInstance(() => ({ x: 0, y: 0 }))
  //   const vel = useInstance(() => ({ x: 0, y: 0 }))
  //   const set = useInstance()

  //   useLayoutEffect(() => {
  //     set.x = gsap.quickSetter(cursorRef.current, 'x', 'px')
  //     set.y = gsap.quickSetter(cursorRef.current, 'y', 'px')
  //     set.r = gsap.quickSetter(cursorRef.current, 'rotate', 'deg')
  //     // set.sx = gsap.quickSetter(cursorRef.current, 'scaleX')
  //     // set.sy = gsap.quickSetter(cursorRef.current, 'scaleY')
  //     // set.width = gsap.quickSetter(cursorRef.current, 'width', 'px')
  //   }, [])

  //   const loop = useCallback(() => {
  //     const rotation = getAngle(vel.x, vel.y)
  //     // const scale = getScale(vel.x, vel.y)

  //     set.x(pos.x)
  //     set.y(pos.y)
  //     // set.width(30 + (scale * 150))
  //     set.r(rotation)
  //     // set.sx(1 + scale)
  //     // set.sy(1 - scale)
  //   }, [])

  //   useLayoutEffect(() => {
  //     const setFromEvent = (e) => {
  //       const x = e.clientX
  //       const y = e.clientY
  //       gsap.to(pos, {
  //         x,
  //         y,
  //         // duration: 1.25,
  //         ease: Expo.easeOut,
  //         onUpdate: () => {
  //           vel.x = x - pos.x
  //           vel.y = y - pos.y
  //         }
  //       })

  //       loop()
  //     }

  //     window.addEventListener('mousemove', setFromEvent)
  //     return () => {
  //       window.removeEventListener('mousemove', setFromEvent)
  //     }
  //   }, [])

  //   useTicker(loop)

  //   return (<></>
  //   // <div ref={cursorRef} className="GooeyCursor">
  //   // </div>
  //   )
  // }

  return (
    <>
     <ContextProvider>
        {/* <GooeyCursor /> */}
        {/* <Header /> */}
        <LoadingPage />
        <PopUpModal />
      </ContextProvider>
    </>
  )
}

export default App
