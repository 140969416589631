import EnteringBlue from '../../image/Entering/Entering1.png'
import EnteringBlack from '../../image/Entering/Entering2.png'

const Entering = () => {
  return (
    <>
      <div>
        <div id="scroll-element-entering" className=' w-0 h-0 opacity-0'></div>
        <div id="enteringTarget" className='h-screen overflow-hidden flex justify-center items-center'>
          <div id="entering-body" className='relative w-[500px] h-[500px]'>
            <img src={ EnteringBlue } alt="Entering-1" className='Entering-1 w-[254px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1]'/>
            <img src={ EnteringBlack } alt="Entering-2" className='Entering-2 w-[163px] absolute top-[15%] left-[-6%] z-[1]'/>
            <img src={ EnteringBlack } alt="Entering-3" className='Entering-3 w-[116px] absolute bottom-[0%] left-[4%]'/>
            <img src={ EnteringBlack } alt="Entering-4" className='Entering-4 w-[188px] absolute top-[10%] left-[-50%]'/>
            <img src={ EnteringBlack } alt="Entering-5" className='Entering-5 w-[97px] absolute bottom-[10%] left-[-40%]'/>
            <img src={ EnteringBlue } alt="Entering-6" className='Entering-6 w-[103px] absolute bottom-[30%] right-[2%]'/>
            <img src={ EnteringBlack } alt="Entering-7" className='Entering-7 w-[180px] absolute bottom-[0%] right-[-50%]'/>
            <img src={ EnteringBlack } alt="Entering-8" className='Entering-8 w-[161px] absolute top-[-5%] right-[-30%]'/>

            <img src={ EnteringBlue } alt="Entering-9" className='Entering-9 w-[223px] absolute top-[0%] left-[0%]'/>
            <img src={ EnteringBlue } alt="Entering-10" className='Entering-10 w-[85px] absolute top-[38%] left-[12%] z-[1]'/>
            <img src={ EnteringBlue } alt="Entering-11" className='Entering-11 w-[64px] absolute bottom-[-11%] left-[20%] z-[1]'/>
            <img src={ EnteringBlue } alt="Entering-12" className='Entering-12 w-[151px] absolute bottom-[21%] left-[-25%] z-[1]'/>
            <img src={ EnteringBlack } alt="Entering-13" className='Entering-13 w-[151px] absolute top-[5%] right-[2%] z-[1]'/>
            <img src={ EnteringBlack } alt="Entering-14" className='Entering-14 w-[117px] absolute bottom-[-10%] right-[10%] z-[1]'/>
            <img src={ EnteringBlue } alt="Entering-15" className='Entering-15 w-[141px] absolute bottom-[0%] right-[-22%] z-[1]'/>
            <img src={ EnteringBlue } alt="Entering-16" className='Entering-16 w-[133px] absolute top-[11%] right-[-51%] z-[1]'/>
            <img src={ EnteringBlue } alt="Entering-17" className='Entering-17 w-[59px] absolute bottom-[18%] right-[-56%] z-[1]'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Entering
