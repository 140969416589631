import HomeAboutUs from './HomeAboutUs'
import HomeAboutEntering from './HomeAboutEntering'
import TableHomeAboutEntering from './TableHomeAboutEntering'

import gradientMobile from '../../image/gradientM.png'

export default function index () {
  const responsive = () => {
    const width = window.innerWidth
    if (width >= 1024) {
      return <HomeAboutEntering />
    }

    if (width > 576 && width < 1023) {
      return <TableHomeAboutEntering />
    }
  }

  return (
    <>
      <div id="homeAboutUsTarget" className="md:flex md:relative">
        <div className="about-us-left h-screen md:flex hidden md:w-[50%] justify-center items-center">
          { responsive() }
        </div>
        <div className="about-us md:w-[50%]">
          <div className="hidden absolute border-l border-black lg:block md:w-[160px] md:gradientV bg-fixed h-full z-20" />
          <img src={ gradientMobile } alt="bg-mobile" className="absolute hidden md:block lg:hidden bg-fixed" />
          <div className="custom-about-width md:self-end md:relative md:pr-10">
            <HomeAboutUs />
          </div>
        </div>
      </div>
    </>
  )
}
