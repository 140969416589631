import HomeJoinEntering from './HomeJoinEntering'
import { WalletButton } from '../ModalButtons'

export default function HomeJoin () {
  return (
    <>
      <div>
        <div id="homeJoinTarget" className='h-screen overflow-hidden flex justify-center items-center'>
          <div className='md:px-[100px] grid grid-rows-1 grid-cols-1 lg:grid-cols-2 w-full'>
            <div
              className='py-40 text-white flex flex-col gap-8 items-center  xl:items-start'
            >
              <p
                data-aos="fade-up"
                data-aos-duration="1200"
                className='font-HalvarEngschrift text-[64px] xl:text-[110px] leading-[64px] xl:leading-[99px] text-center xl:mb-[70px] xl:text-left'
              >
                  JOIN<br />FIRSTCLASS
              </p>
              <div id={'joinBtn'}><WalletButton/></div>
            </div>
            <div className="lg:flex justify-center items-center hidden">
              <HomeJoinEntering />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
