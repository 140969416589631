import React from 'react'
import MobileHomeAboutUs from '../components/HomeAbout/MobileHomeAboutUs'
import HomeAbout from '../components/HomeAbout'
import HomeProject from '../components/HomeProject'
import HomeTeam from '../components/HomeTeam'
import HomeJoin from '../components/HomeJoin/HomeJoin'
import { Footer } from '../components/Footer'
import bgAbout from '../image/mobile-about-bg.png'

export default function HomePage () {
  return (
    <>
      <div className="home-page">
        <div id="scroll-element-about" className=" w-0 h-0 opacity-0"></div>
        <div className="view-in show overflow-hidden">
          {window.innerWidth > 767
            ? (<HomeAbout/>)
            : (
              <>
                <div
                  id="MobileHomeAboutUsTarget"
                  className='relative h-screen'
                >
                  <div className='bg-about absolute'>
                    <img src={bgAbout} alt="" className='block w-full' />
                  </div>
                  <MobileHomeAboutUs />
                </div>
              </>
              )}
        </div>
        <HomeProject />
        <HomeTeam />
        <HomeJoin />
        <Footer />
      </div>
    </>
  )
}
