import { createContext, useState, useRef, useContext, useCallback } from 'react'

export const Context = createContext()

export const ContextProvider = ({ children }) => {
  const containerEl = useRef(null)

  const [modal, setModal] = useState(null)
  const [popUp, setPopUp] = useState({
    open: false
  })

  const togglePopUp = () => {
    setPopUp((state) => ({ ...state, open: !popUp.open }))
  }

  const showModal = useCallback((modal) => {
    setModal(modal)
  }, [])

  const hideModal = useCallback(() => {
    setModal(null)
  }, [])

  return (
    <Context.Provider value={{ popUp, togglePopUp, containerEl, showModal, hideModal }}>
      {children}
      {modal}
    </Context.Provider>
  )
}

export function useModal () {
  const context = useContext(Context)
  return context
}
