import { useState, useEffect } from 'react'
import Aos from 'aos'
import gsap from 'gsap'
import loadingImg from '../image/loadingImg.gif'
import Mosaic from '../components/Mosaic'
import { StarsBackground } from '../components/StarsBackground'
import HomePage from './HomePage'
import Entering from '../components/Entering'
import logo from '../image/logo.svg'
import scrollCore from '../utils/scrollCore.js'
import { WalletButton } from '../components/ModalButtons'
import { Link } from 'react-router-dom'

const LoadingPage = () => {
  const [loading, setLoading] = useState(true)
  const [before, setBefore] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000)
    const tl = gsap.timeline()
    tl.set(document.body, {
      overflow: 'hidden'
    })
  }, [])

  useEffect(() => {
    if (!before) {
      Aos.init()
      // 為了解決 aos 無法搭配的監聽 bug
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('aos-animate')
          } else {
            entry.target.classList.remove('aos-animate')
          }
        })
      })
      document.querySelectorAll('[data-aos]').forEach((aosElem) => {
        observer.observe(aosElem)
      })
      document.body.removeAttribute('style')
      scrollCore()
    }
  }, [before])

  return (
    <>
      <StarsBackground />
      {
        before &&
        <div id="loading">
          {
            loading
              ? (
                <div className='bg-black fixed z-[999] inset-0'>
                  <img
                    className={
                      ['fixed top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] transition-all duration-500 ease-in-out',
                        loading ? 'opacity-100' : 'opacity-0'
                      ].join(' ')
                    }
                    src={loadingImg}
                    alt="loadingImg"
                  />
                </div>
                )
              : (
                <div className='fixed z-50 inset-0'>
                  <Mosaic loading={loading} setBefore={setBefore} />
                </div>)
          }
        </div>
      }
      <div className='viewContainer'>
      <Link to="/" id="homeId">
        <img
          className='md:block inLogo w-[193px] h-[25px] cursor-pointer fixed top-[20px] left-[20px] lg:top-10 lg:left-10 md:top-[25px] z-10'
          src={logo}
          alt='logo'
        /> </Link>
        <div id="fixedButton" className='inButton inline-flex flex-col justify-center items-center gap-[20px] mb-5 lg:gap-3 lg:flex-row lg:justify-start gasp-btnHidden fixed left-0 md:left-[20px] right-0 lg:right-auto bottom-[20px] md:bottom-[25px] lg:bottom-10 lg:left-10 z-10 gasp-btnHidden'>
          {/* <BtnSecondary>Manifesto</BtnSecondary> */}
          <WalletButton/>
        </div>
      </div>
      <div id="homePage-container">
        <div className='md:fixed z-40 inset-0 enter-wrapper pointer-events-none'>
          <Entering key={Math.random()} />
        </div>
        <HomePage key={Math.random()} />
      </div>
    </>
  )
}

export default LoadingPage
