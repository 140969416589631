import React from 'react'
import arrowDown from '../image/arrowDown.svg'

export default function ScrollArrowDown () {
  return (
    <>
      <button
        id="scrollDown"
        className='scrollDown-position absolute bottom-[55px] right-[24px] flex items-center gap-[10px] font-HalvarMittelschrift text-sm'>
        FIRSTCLASS PROJECT
        <img
          className='w-[15px] h-[15px]'
          src={arrowDown}
          alt='arrowDown'
        />
      </button>
    </>
  )
}
