import React, { useRef, useEffect } from 'react'
import { StarField, IS_MOBILE } from '../utils/StarField'

export const StarsBackground = () => {
  const canvas = useRef(null)
  function setup () {
    let howManyStars = 1000
    if (IS_MOBILE) { howManyStars = 500 }
    const starfield = new StarField(howManyStars, canvas.current)
    starfield.startRenderLoop()
  }

  useEffect(() => {
    setup()
  }, [])

  return (
    <div className='fixed inset-0 -z-30'>
      <canvas ref={canvas} id="canvas"></canvas>
    </div>
  )
}
