import EnteringBlue from '../../image/Entering/Entering1.png'
import EnteringBlack from '../../image/Entering/Entering2.png'

const Entering = () => {
  return (
    <>
      <div>
        <div id="scroll-element-entering" className=' w-0 h-0 opacity-0'></div>
        <div id="tableEnteringTarget" className='h-screen overflow-hidden flex justify-center items-center'>
          <div id="entering-body" className='relative w-[400px] h-[400px]'>
            <img src={ EnteringBlue } alt="EnteringBlue-1" className='table-entering-1 absolute left-[-30%] top-[40%] w-[138px] z-[1]'/>
            <img src={ EnteringBlue } alt="EnteringBlue-2" className='table-entering-2 absolute left-[8%] top-[35%] w-[78px] z-[1]'/>
            <img src={ EnteringBlue } alt="EnteringBlue-3" className='table-entering-3 absolute left-[0%] top-[-8%] w-[203px]'/>
            <img src={ EnteringBlue } alt="EnteringBlue-4" className='table-entering-4 absolute right-[-32%] bottom-[0%] w-[129px]'/>
            <img src={ EnteringBlack } alt="EnteringBlue-5" className='table-entering-5 absolute right-[10%] bottom-[-8%] w-[106px] z-[1]'/>
            <img src={ EnteringBlue } alt="EnteringBlue-6" className='table-entering-6 absolute left-[18%] bottom-[-20%] w-[58px]'/>
            <img src={ EnteringBlack} alt="EnteringBlue-7" className='table-entering-7 absolute right-[-6%] top-[-6%] w-[156px]'/>
            <img src={ EnteringBlue } alt="EnteringBlack-1" className='table-entering-8 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[231px]' />
            <img src={ EnteringBlack } alt="EnteringBlack-2" className='table-entering-9 absolute left-[-20%] top-[0%] w-[149px]'/>
            <img src={ EnteringBlack } alt="EnteringBlack-3" className='table-entering-10 absolute left-[-5%] bottom-[0%] w-[106px] z-[1]'/>
            <img src={ EnteringBlue } alt="EnteringBlack-4" className='table-entering-11 absolute right-[-7%] bottom-[29%] w-[94px]'/>
            <img src={ EnteringBlack } alt="EnteringBlack-5" className='table-entering-12 absolute right-[-38%] top-[-20%] w-[147px]'/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Entering
