import gsap from 'gsap'
import LocomotiveScroll from 'locomotive-scroll'
import ScrollTrigger from 'gsap/ScrollTrigger'
import CustomEase from 'gsap/CustomEase'
import { handlePointer } from './StarField'
import Frame from '../image/Frame.svg'
import logo from '../image/logo.svg'

function scrollCore () {
  const containerEl = document.querySelector('#homePage-container')
  let multiplier = 0.5
  let lerp = 0.05
  const mediaQueryLg = window.matchMedia('(max-width: 992px)')
  if (mediaQueryLg.matches) {
    multiplier = 2
    lerp = 0.07
  }
  const locoScroll = new LocomotiveScroll({
    el: containerEl,
    smooth: true,
    lerp,
    multiplier,
    touchMultiplier: 1.5,
    firefoxMultiplier: 100,
    smartphone: {
      smooth: true
    },
    tablet: {
      smooth: true
    }
  })
  gsap.registerPlugin(ScrollTrigger, CustomEase)
  locoScroll.on('scroll', ScrollTrigger.update)

  ScrollTrigger.scrollerProxy(containerEl, {
    scrollTop (value) {
      return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y
    },
    getBoundingClientRect () {
      return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
    },
    pinType: containerEl.style.transform ? 'transform' : 'fixed'
  })

  const fixedButton = document.querySelector('#fixedButton')
  ScrollTrigger.create({
    id: '#homePage-container',
    start: 'top top',
    end: 99999,
    // scrub: true,
    scroller: '#homePage-container',
    // markers: true
    onUpdate (self) {
      if (self.direction === -1) {
        if (window.innerWidth >= 992) {
          if (self.progress.toFixed(3) < 0.067) {
            gsap.to(fixedButton, {
              duration: 0.5,
              opacity: 1,
              ease: 'power2.out',
              display: 'flex'
            })
            gsap.to('.enter-wrapper', {
              duration: 0.5,
              zIndex: 40
            })
          }
        }

        if (window.innerWidth >= 768 && window.innerWidth < 991) {
          if (self.progress.toFixed(3) < 0.006) {
            gsap.to(fixedButton, {
              duration: 0.5,
              opacity: 1,
              ease: 'power2.out',
              display: 'flex'
            })
            gsap.to('.enter-wrapper', {
              duration: 0.5,
              zIndex: 40
            })
          }
        }

        if (window.innerWidth < 767) {
          if (self.progress.toFixed(3) < 0.006) {
            gsap.to(fixedButton, {
              duration: 0.5,
              opacity: 1,
              ease: 'power2.out',
              display: 'flex'
            })
            gsap.to('.enter-wrapper', {
              duration: 0.5,
              zIndex: 40
            })
          }
        }
      } else {
        if (self.progress.toFixed(3) > 0.002) {
          setTimeout(() => {
            handlePointer({
              clientX: window.innerWidth / 2,
              clientY: 0
            })
          })

          if (window.innerWidth >= 992) {
            if (self.progress.toFixed(3) > 0.067) {
              gsap.to(fixedButton, {
                duration: 0.5,
                opacity: 0,
                ease: 'power2.out',
                display: 'none'
              })
              gsap.to('.enter-wrapper', {
                duration: 0.5,
                zIndex: -1
              })
            }
          }

          if (window.innerWidth >= 768 && window.innerWidth < 991) {
            if (self.progress.toFixed(3) > 0.006) {
              gsap.to(fixedButton, {
                duration: 0.5,
                opacity: 0,
                ease: 'power2.out',
                display: 'none'
              })
              gsap.to('.enter-wrapper', {
                duration: 0.5,
                zIndex: -1
              })
            }
          }

          if (window.innerWidth < 767) {
            if (self.progress.toFixed(3) > 0.006) {
              gsap.to(fixedButton, {
                duration: 0.5,
                opacity: 0,
                ease: 'power2.out',
                display: 'none'
              })
              gsap.to('.enter-wrapper', {
                duration: 0.5,
                zIndex: -1
              })
            }
          }
        }
      }
    }
  })
  setInterval(() => {
    handlePointer({
      clientX: window.innerWidth / 2,
      clientY: window.innerHeight / 2 + 50
    })
  }, 200)

  function scrollTarget ({ el, target }) {
    const Element = document
      .querySelector(el)
    if (!Element) return
    Element.addEventListener('click', (e) => {
      e.preventDefault()
      locoScroll.scrollTo(target, {
        duration: 500
      })
    })
  }

  const scrollTargets = [
    {
      el: '#homeId',
      target: '#scroll-element-entering'
    },
    {
      el: '#projectId',
      target: '#scroll-element-project'
    },
    {
      el: '#teamId',
      target: '#scroll-element-team'
    },
    {
      el: '#scrollDown',
      target: '#scroll-element-project'
    },
    {
      el: '#footerLogo',
      target: '#scroll-element-entering'
    },
    {
      el: '#footerLogo',
      target: '#scroll-element-entering'
    },
    {
      el: '#projectFooter',
      target: '#scroll-element-project'
    },
    {
      el: '#teamFooter',
      target: '#scroll-element-team'
    }
  ]
  scrollTargets.forEach((item) => {
    scrollTarget(item)
  })

  const labsId = document
    .querySelector('#labsId')
  if (labsId) {
    labsId.addEventListener('click', (e) => {
      e.preventDefault()
      locoScroll.scrollTo(window.innerHeight * 1.2, { duration: 500 })
    })
  }

  const labsFooter = document
    .querySelector('#labsFooter')
  if (labsFooter) {
    labsFooter.addEventListener('click', (e) => {
      e.preventDefault()
      locoScroll.scrollTo(window.innerHeight * 1.2, { duration: 500 })
    })
  }

  // Logo
  const homeId = document.querySelector('#homeId')
  if (homeId && window.innerWidth <= 767) {
    // const walletId = document.querySelector('#walletId')
    locoScroll.on('scroll', (e) => {
      if (e.scroll.y > window.innerHeight * 1.2) {
        // walletId.style.opacity = 1
        setTimeout(() => {
          homeId.querySelector('img').src = Frame
        })
      } else {
        // walletId.style.opacity = 0
        setTimeout(() => {
          homeId.querySelector('img').src = logo
        })
      }
    })
  }

  if (homeId && (window.innerWidth >= 768 && window.innerWidth <= 1023)) {
    const topHeader = document.querySelector('.topHeader')
    locoScroll.on('scroll', (e) => {
      if (e.scroll.y > window.innerHeight * 1.2) {
        topHeader.classList.remove('md:opacity-0')
      } else {
        topHeader.classList.add('md:opacity-0')
      }
    })
  }

  // Entering Start
  const enteringTargetNode = document.querySelector('#enteringTarget')
  if (enteringTargetNode) {
    gsap.timeline({
      scrollTrigger: {
        trigger: enteringTargetNode,
        start: 'top top',
        end: window.innerHeight,
        scroller: containerEl,
        pin: true
      }
    })

    const duration = 4
    const scale = 1.7
    const ease = CustomEase.create('custom', 'M0,0 C0.2,0.018 0.386,0.01 0.668,0.174 0.921,0.321 0.97,0.646 1,1 ')
    const scrollTrigger = {
      trigger: enteringTargetNode,
      start: 'top 0.1%',
      end: window.innerHeight,
      scroller: containerEl,
      force3D: true,
      // markers: true,
      scrub: 1
    }
    gsap.to('.Entering-1', {
      scrollTrigger,
      duration,
      x: 0,
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-2', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: () => -(window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-3', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-4', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: 0,
      scale,
      ease
    })
    gsap.to('.Entering-5', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-6', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-7', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-8', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => -(window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-9', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: () => -(window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-10', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: 0,
      scale,
      ease
    })
    gsap.to('.Entering-11', {
      scrollTrigger,
      duration,
      x: 0,
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-12', {
      scrollTrigger,
      duration,
      x: () => -(window.innerHeight * 2),
      y: 0,
      scale,
      ease
    })
    gsap.to('.Entering-13', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => -(window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-14', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-15', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => (window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-16', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: () => -(window.innerHeight * 2),
      scale,
      ease
    })
    gsap.to('.Entering-17', {
      scrollTrigger,
      duration,
      x: () => (window.innerHeight * 2),
      y: 0,
      scale,
      ease
    })
  }

  const tableEnteringTarget = document.querySelector('#tableEnteringTarget')
  if (tableEnteringTarget) {
    gsap.timeline({
      scrollTrigger: {
        trigger: tableEnteringTarget,
        start: 'top top',
        end: window.innerHeight,
        scroller: containerEl,
        // markers: true,
        // pinSpacing: false,
        // scrub: .7,
        pin: true
      }
    })

    const duration = 4
    const scale = 1.7
    const ease = CustomEase.create('custom', 'M0,0 C0.2,0.018 0.386,0.01 0.668,0.174 0.921,0.321 0.97,0.646 1,1 ')
    const scrollTrigger = {
      trigger: enteringTargetNode,
      start: 'top 0.1%',
      end: window.innerHeight,
      scroller: containerEl,
      force3D: true,
      // markers: true,
      scrub: 1
    }

    gsap.to('.table-entering-1', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 1),
      y: 0,
      scale,
      ease
    })

    gsap.to('.table-entering-2', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 1),
      y: 0,
      scale,
      ease
    })

    gsap.to('.table-entering-3', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 1),
      y: () => -(window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-4', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 1),
      y: () => (window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-5', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 1),
      y: () => (window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-6', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 1),
      y: () => (window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-7', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 1),
      y: () => -(window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-8', {
      scrollTrigger,
      duration,
      x: 0,
      y: () => (window.innerWidth * 2),
      scale,
      ease
    })

    gsap.to('.table-entering-9', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 1),
      y: () => -(window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-10', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 1),
      y: () => (window.innerWidth * 1),
      scale,
      ease
    })

    gsap.to('.table-entering-11', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 1),
      y: 0,
      scale,
      ease
    })

    gsap.to('.table-entering-12', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 1),
      y: () => -(window.innerWidth * 1),
      scale,
      ease
    })
  }

  const mobileEnteringTarget = document.querySelector('#mobileEnteringTarget')
  if (mobileEnteringTarget) {
    gsap.timeline({
      scrollTrigger: {
        trigger: mobileEnteringTarget,
        start: 'top top',
        end: window.innerHeight,
        scroller: containerEl,
        // markers: true,
        // pinSpacing: false,
        // scrub: .7,
        pin: true
      }
    })

    const duration = 4
    const scale = 1.7
    const ease = CustomEase.create('custom', 'M0,0 C0.2,0.018 0.386,0.01 0.668,0.174 0.921,0.321 0.97,0.646 1,1 ')
    const scrollTrigger = {
      trigger: enteringTargetNode,
      start: 'top 0.1%',
      end: window.innerHeight,
      scroller: containerEl,
      force3D: true,
      // markers: true,
      scrub: 1
    }
    gsap.to('.Entering-1', {
      scrollTrigger,
      duration,
      x: 0,
      y: () => (window.innerWidth * 2),
      scale,
      ease
    })

    gsap.to('.Entering-2', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 2),
      y: () => -(window.innerWidth * 2),
      scale,
      ease
    })

    gsap.to('.Entering-3', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 2),
      y: () => (window.innerWidth * 2),
      scale,
      ease
    })

    gsap.to('.Entering-4', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 2),
      y: 0,
      scale,
      ease
    })

    gsap.to('.Entering-5', {
      scrollTrigger,
      duration,
      x: () => -(window.innerWidth * 2),
      y: 0,
      scale,
      ease
    })

    gsap.to('.Entering-6', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 2),
      y: () => (window.innerWidth * 2),
      scale,
      ease
    })

    gsap.to('.Entering-7', {
      scrollTrigger,
      duration,
      x: () => (window.innerWidth * 2),
      y: () => -(window.innerWidth * 2),
      scale,
      ease
    })
  }

  // HomeAboutUs Start
  const MobileHomeAboutUsTargetNode = document.querySelector('#MobileHomeAboutUsTarget')
  const MobileHomeAboutUsScrollContent = gsap.utils.toArray('.about-scroll-content')
  const MobileHomeAboutUsBody = document.querySelector('#MobileHomeAboutUsBody')
  if (MobileHomeAboutUsTargetNode) {
    gsap.timeline({
      scrollTrigger: {
        trigger: MobileHomeAboutUsTargetNode,
        start: 'top top',
        end: () => `+=${(MobileHomeAboutUsBody.offsetHeight - MobileHomeAboutUsScrollContent[0].offsetHeight)} `,
        scroller: containerEl,
        // markers: true,
        pin: true
      }
    })
    MobileHomeAboutUsScrollContent.forEach((el, idx) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top bottom',
          scroller: containerEl,
          // markers: true,
          scrub: 1
        }
      })
      if (idx < MobileHomeAboutUsScrollContent.length - 1) {
        tl.to(el, {
          duration: 0.2,
          keyframes: [
            {
              y: () => -(el.offsetHeight * (idx))
            },
            {
              opacity: 1
            }
          ]
        }).to(el, {
          duration: 0.2,
          keyframes: [
            {
              y: () => -(el.offsetHeight * (idx + 1))
            },
            {
              opacity: 1
            }
          ]
        })
      } else {
        // const tl2 = gsap.timeline({
        //   scrollTrigger: {
        //     trigger: el,
        //     start: 'top top',
        //     scroller: containerEl,
        //     // markers: true,
        //     scrub: 1
        //   }
        // })
        tl.to(el, {
          duration: 0.2,
          keyframes: [
            {
              y: () => -(el.offsetHeight * (idx))
            },
            {
              opacity: 1
            }
          ]
        }).to('.bg-about', {
          duration: 0.2,
          keyframes: [
            {
              y: () => el.offsetHeight / 2
            },
            {
              opacity: 1
            }
          ]
        })
      }
    })
  }

  const homeAboutUsTargetNode = document.querySelector('#homeAboutUsTarget')
  const homeAboutUsScrollContent = gsap.utils.toArray('.about-scroll-content')
  const homeAboutUsBody = document.querySelector('#homeAboutUsBody')
  if (homeAboutUsTargetNode) {
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsTargetNode,
        start: 'top top',
        end: () => `+=${(homeAboutUsBody.offsetHeight * homeAboutUsScrollContent.length) + 1500} `,
        scroller: containerEl,
        // markers: true,
        pin: true
      }
    })

    const duration = () => gsap.utils.random(3.5, 4.5)
    const ease = CustomEase.create('custom', 'M0,0 C0.2,0.018 0.386,0.01 0.668,0.174 0.921,0.321 0.97,0.646 1,1 ')

    gsap.timeline({
      scrollTrigger: {
        trigger: '.about-us',
        start: 'top top',
        end: '+=' + window.innerHeight * 2,
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    }).to('.home-page', {
      opacity: 1,
      duration: 0
    })
    gsap.timeline({
      scrollTrigger: {
        trigger: '.about-us',
        start: 'bottom bottom',
        end: '+=' + window.innerHeight * 1.1,
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    }).to('.about-us-left', {
      ease,
      x: '0%',
      y: '0%'
    })
    gsap.timeline({
      scrollTrigger: {
        trigger: '.about-us',
        start: 'bottom bottom',
        end: '+=' + window.innerHeight * 1.1,
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    }).to('.about-us', {
      ease,
      x: '100%'
    }).to('.about-us', {
      ease,
      x: '0%'
    })

    const content1 = 1
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsScrollContent[content1],
        start: 'top top',
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    })
      .to(homeAboutUsScrollContent[content1], {
        // y: () => -(homeAboutUsBody.offsetHeight * content1),
        // opacity: 1,
        keyframes: [
          {
            y: () => -(homeAboutUsBody.offsetHeight * content1)
          },
          {
            opacity: 1
          }
        ]
      }, content1)
      .to('.About-EnteringBlack-item--3', {
        ease,
        duration,
        x: () => -(homeAboutUsBody.offsetHeight * 0.7),
        y: () => (homeAboutUsBody.offsetHeight * 1.4),
        scale: 1.7
      }, content1)

    const content2 = 2
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsScrollContent[content2],
        start: 'top top',
        end: 'bottom',
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    })
      .to(homeAboutUsScrollContent[content2], {
        keyframes: [
          {
            y: () => -(homeAboutUsBody.offsetHeight * content2)
          },
          {
            opacity: 1
          }
        ]
      }, content2)
      .to('.About-EnteringBlue-item--2', {
        ease,
        duration,
        x: () => (homeAboutUsBody.offsetHeight * 1),
        y: () => (homeAboutUsBody.offsetHeight * 1),
        scale: 1.7
      }, content2)

    const content3 = 3
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsScrollContent[content3],
        start: 'top top',
        end: 'bottom',
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    })
      .to(homeAboutUsScrollContent[content3], {
        keyframes: [
          {
            y: () => -(homeAboutUsBody.offsetHeight * content3)
          },
          {
            opacity: 1
          }
        ]
      }, content3)
      .to('.About-EnteringBlue-item--3', {
        ease,
        duration,
        x: () => -(window.innerWidth * 1),
        y: () => 0,
        scale: 1.7
      }, content3)

    const content4 = 4
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsScrollContent[content4],
        start: 'top top',
        end: 'bottom',
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    })
      .to(homeAboutUsScrollContent[content4], {
        keyframes: [
          {
            y: () => -(homeAboutUsBody.offsetHeight * content4)
          },
          {
            opacity: 1
          }
        ]
      }, content4)
      .to('.About-EnteringBlack-item--4', {
        ease,
        duration,
        x: () => (homeAboutUsBody.offsetHeight * 1),
        y: () => (homeAboutUsBody.offsetHeight * 1),
        scale: 1.7
      }, content4)

    const content5 = 5
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsScrollContent[content5],
        start: 'top top',
        end: 'bottom',
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    })
      .to(homeAboutUsScrollContent[content5], {
        keyframes: [
          {
            y: () => -(homeAboutUsBody.offsetHeight * content5)
          },
          {
            opacity: 1
          }
        ]
      }, content5)
      .to('.About-EnteringBlack-item--2', {
        ease,
        duration,
        x: () => -(window.innerWidth * 1),
        y: () => -(window.innerHeight * 1),
        scale: 1.7
      }, content5)

    const content6 = 6
    gsap.timeline({
      scrollTrigger: {
        trigger: homeAboutUsScrollContent[content6],
        start: 'top top',
        end: 'bottom',
        scroller: containerEl,
        // markers: true,
        scrub: 1
      }
    })
      .to(homeAboutUsScrollContent[content6], {
        keyframes: [
          {
            y: () => -(homeAboutUsBody.offsetHeight * content6)
          },
          {
            opacity: 1
          }
        ]
      }, content6)
      .to('.About-EnteringBlue-item--1', {
        ease,
        duration,
        x: () => (window.innerWidth * 0.7),
        y: () => -(window.innerHeight * 0.7),
        scale: 1.7
      }, content6)

    // const content1 = 1
    // const content2 = 2
    // const content3 = 3
    // const content4 = 4
    // const content5 = 5
    // const content6 = 6

    // gsap.timeline({
    //   scrollTrigger: {
    //     trigger: homeAboutUsScrollContent[content1],
    //     start: 'top -=200%',
    //     end: () => `+=${(homeAboutUsBody.offsetHeight * homeAboutUsScrollContent.length) + 1500} `,
    //     scroller: containerEl,
    //     // markers: true,
    //     scrub: 1
    //   }
    // })
    //   .to(homeAboutUsScrollContent[content1], {
    //     opacity: 1
    //   }, content1)
    //   .to('.About-EnteringBlack-item--3', {
    //     ease,
    //     duration,
    //     x: () => -(homeAboutUsBody.offsetHeight * 6),
    //     y: () => (homeAboutUsBody.offsetHeight * 6),
    //     scale: 1.7
    //   }, content1)

    //   .to(homeAboutUsScrollContent[content2], {
    //     opacity: 1
    //   }, content2)
    //   .to('.About-EnteringBlue-item--2', {
    //     ease,
    //     duration,
    //     x: () => (homeAboutUsBody.offsetHeight * 6),
    //     y: () => (homeAboutUsBody.offsetHeight * 6),
    //     scale: 1.7
    //   }, content2)

    //   .to(homeAboutUsScrollContent[content3], {
    //     opacity: 1
    //   }, content3)
    //   .to('.About-EnteringBlue-item--3', {
    //     ease,
    //     duration,
    //     x: () => -(window.innerWidth * 1),
    //     y: () => 0,
    //     scale: 1.7
    //   }, content3)

    //   .to(homeAboutUsScrollContent[content4], {
    //     opacity: 1
    //   }, content4)
    //   .to('.About-EnteringBlack-item--4', {
    //     ease,
    //     duration,
    //     x: () => (homeAboutUsBody.offsetHeight * 6),
    //     y: () => (homeAboutUsBody.offsetHeight * 6),
    //     scale: 1.7
    //   }, content4)

    //   .to(homeAboutUsScrollContent[content5], {
    //     opacity: 1
    //   }, content5)
    //   .to('.About-EnteringBlack-item--2', {
    //     ease,
    //     duration,
    //     x: () => -(window.innerWidth * 6),
    //     y: () => -(window.innerHeight * 6),
    //     scale: 1.7
    //   }, content5)

    //   .to(homeAboutUsScrollContent[content6], {
    //     opacity: 1
    //   }, content6)
    //   .to('.About-EnteringBlue-item--1', {
    //     ease,
    //     duration,
    //     x: () => (window.innerWidth * 6),
    //     y: () => -(window.innerHeight * 6),
    //     scale: 1.7
    //   }, content6)
  }

  // Marquee Start
  const marqueeTextNode = document.querySelectorAll('.marquee-text')
  if (marqueeTextNode.length) {
    const homeProjectTargetNode = document.querySelector('#homeProjectTarget')

    gsap.to(marqueeTextNode[0], {
      x: () => -(marqueeTextNode[0].offsetHeight) * 8,
      scrollTrigger: {
        trigger: homeProjectTargetNode,
        scroller: containerEl,
        start: 'top 100%',
        scrub: 1
        // markers: true
      }
    })
  }

  const homeTeamTargetNode = document.querySelector('#homeTeamTarget')
  if (homeTeamTargetNode) {
    gsap.to(marqueeTextNode[1], {
      x: () => -(marqueeTextNode[1].offsetHeight) * 8,
      scrollTrigger: {
        trigger: homeTeamTargetNode,
        scroller: containerEl,
        start: 'top 100%',
        scrub: 1
        // markers: true
      }
    })
  }

  // HomeProject Start｀
  const cardPt = document.querySelector('.card-pt')
  if (cardPt) {
    const removeImageAniClass = () => {
      const overlay = document.querySelectorAll('.product-img-overlay')
      overlay.forEach(ele => {
        ele.classList.remove('left-animated')
        ele.classList.remove('left-animated-2')
      })
      const imgAni = document.querySelectorAll('.product-img')
      imgAni.forEach(ele => {
        ele.classList.remove('animated')
      })
    }
    const addImageAniClass = () => {
      const overlay = document.querySelectorAll('.product-img-overlay')
      const imgAni = document.querySelectorAll('.product-img')
      setTimeout(() => {
        for (let i = 0; i < overlay.length; ++i) {
          overlay[i].classList.add('left-animated')
        }
      }, 300)

      setTimeout(() => {
        for (let i = 0; i < overlay.length; ++i) {
          overlay[i].classList.add('left-animated-2')
        }
        for (let j = 0; j < imgAni.length; ++j) {
          imgAni[j].classList.add('animated')
        }
      }, 800)
    }
    gsap.to(cardPt, {
      scrollTrigger: {
        trigger: cardPt,
        scroller: containerEl,
        start: 'top bottom',
        end: 'bottom top',
        invalidateOnRefresh: true,
        scrub: true,
        onEnter () {
          addImageAniClass()
        },
        onLeave () {
          removeImageAniClass()
        },
        onEnterBack () {
          addImageAniClass()
        },
        onLeaveBack () {
          removeImageAniClass()
        }
      }
    })
  }

  // HomeJoin Start
  const homeJoinTargetNode = document.querySelector('#homeJoinTarget')
  if (homeJoinTargetNode) {
    // const duration = () => gsap.utils.random(2.5, 3.5)
    // const ease = CustomEase.create('custom', 'M0,0 C0.2,0.018 0.386,0.01 0.668,0.174 0.921,0.321 0.97,0.646 1,1 ')

    // const EnteringJoinItems = document.querySelectorAll('.EnteringJoin')
    // EnteringJoinItems.forEach((el, idx) => {
    //   const tl = gsap.timeline({
    //     repeat: -1,
    //     repeatRefresh: true,
    //     force3D: true,
    //     scrub: 1,
    //     scrollTrigger: {
    //       start: 'top 50%',
    //       trigger: homeJoinTargetNode,
    //       scroller: containerEl,
    //       // markers: true,
    //       onEnter () {
    //         tl.play()
    //       },
    //       onLeave () {
    //         tl.pause()
    //       },
    //       onEnterBack () {
    //         tl.play()
    //       },
    //       onLeaveBack () {
    //         tl.pause()
    //       }
    //     }
    //   })
    //   tl.set(el, {
    //     scale: 0,
    //     opacity: 1
    //   }, idx)
    //   tl.to(el, {
    //     duration,
    //     ease,
    //     keyframes: [
    //       {
    //         x: gsap.utils.random(-window.innerWidth * 0.5, window.innerWidth * 0.5),
    //         y: gsap.utils.random(-window.innerHeight * 0.5, window.innerHeight * 0.5),
    //         scale: 1.8
    //       },
    //       {
    //         opacity: 0,
    //         delay: -0.4
    //       },
    //       {
    //         x: 0,
    //         y: 0,
    //         scale: 0
    //       }
    //     ]
    //   }, idx)
    // })
    const ease = CustomEase.create('custom', 'M0,0 C0.448,0.012 0.324,1.012 0.384,1.012 0.644,1.012 0.818,1.013 1,1.012 ')
    const duration = 1.5
    const tl = gsap.timeline({
      paused: true,
      scale: 1.7
    })
    tl.to('.Join-EnteringBlack-item--3', {
      ease,
      duration,
      x: () => -(homeJoinTargetNode.offsetHeight * 0.7),
      y: () => (homeJoinTargetNode.offsetHeight * 1.4)
    }, 0)

    tl.to('.Join-EnteringBlue-item--2', {
      ease,
      duration,
      x: () => (homeJoinTargetNode.offsetHeight * 1),
      y: () => (homeJoinTargetNode.offsetHeight * 1)
    }, 0)

    tl.to('.Join-EnteringBlue-item--3', {
      ease,
      duration,
      x: () => -(window.innerWidth * 1),
      y: () => 0
    }, 0)

    tl.to('.Join-EnteringBlack-item--4', {
      ease,
      duration,
      x: () => (homeJoinTargetNode.offsetHeight * 1),
      y: () => (homeJoinTargetNode.offsetHeight * 1)
    }, 0)

    tl.to('.Join-EnteringBlack-item--2', {
      ease,
      duration,
      x: () => -(window.innerWidth * 1),
      y: () => -(window.innerHeight * 1)
    }, 0)

    tl.to('.Join-EnteringBlue-item--1', {
      ease,
      duration,
      x: () => (window.innerWidth * 0.7),
      y: () => -(window.innerHeight * 0.7)
    }, 0)

    const joinBtn = document.querySelector('#joinBtn')
    joinBtn.addEventListener('mouseenter', (e) => {
      tl.play()
    })
    joinBtn.addEventListener('mouseleave', (e) => {
      tl.reverse()
    })
  }

  ScrollTrigger.addEventListener('refresh', () => locoScroll.update())

  ScrollTrigger.refresh()

  window.addEventListener('resize', () => {
    // 大於 768 並且不是 Safari 的話就執行 reload
    if (window.innerWidth > 768 && !navigator.userAgent.includes('Safari')) {
      window.location.reload()
    }
  })
}

export default scrollCore
