export const BtnPrimaryHeader = (props) => {
  return (
    <button
      className="btn-primary-header text-[20px] md:text-base md:ml-3 font-HalvarEngschrift">
      <span className="relative z-10">{props.children}</span>
    </button>
  )
}

export const BtnSecondaryHeader = (props) => {
  return (
    <a href="https://docs.firstclass.wtf/about/manifesto" target='_blank'
      className="hidden md:flex btn-Secondary-header ml-14 font-HalvarEngschrift" rel="noreferrer">
      <p className="relative z-10">{props.children}</p>
      <svg className='btn-Secondary-header-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3643 2.71543H6.15066L6.15066 1.05155e-06L24.0002 0L24.0002 17.8496H21.2848L21.2848 4.63519L1.91998 24L-0.00012207 22.0799L19.3643 2.71543Z" fill="currentcolor" />
      </svg>
    </a>
  )
}

export const BtnPrimary = (props) => {
  return (
    <button
      className="btn-primary-header font-HalvarEngschrift py-[7px] text-[30px]" onClick={props.onClick} style={props.style}>
      <span className="relative z-10">{props.children}</span>
    </button>
  )
}

export const BtnSecondary = (props) => {
  return (
    <a href="https://docs.firstclass.wtf/about/manifesto" target='_blank'
      className="flex btn-Secondary-header font-HalvarEngschrift" rel="noreferrer">
      <p className="relative z-10 text-[24px]">{props.children}</p>
      <svg className='btn-Secondary-header-icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3643 2.71543H6.15066L6.15066 1.05155e-06L24.0002 0L24.0002 17.8496H21.2848L21.2848 4.63519L1.91998 24L-0.00012207 22.0799L19.3643 2.71543Z" fill="currentcolor" />
      </svg>
    </a>
  )
}

export const BtnPrimaryJoin = ({ children, id }) => {
  return (
    <button
      id={ id }
      className="btn-primary-header text-[20px] md:text-[30px] font-HalvarEngschrift">
      <span className="relative z-10">{ children }</span>
    </button>
  )
}
