
import EnteringBlue from '../../image/Entering/Entering1.png'
import EnteringBlack from '../../image/Entering/Entering2.png'

const HomeAboutEntering = () => {
  return (
    <>
      <div id="HomeAboutEnteringTarget">
        <div className=' w-[300px] h-[300px] relative'>
        <img
            className='About-EnteringBlack-item--1 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1]'
            src={EnteringBlue}
            alt="EnteringBlack-item--1"
          />
          <img
            className='About-EnteringBlack-item--2 absolute left-[-40%] top-[-30%] scale-[0.8]'
            src={EnteringBlack}
            alt="EnteringBlack-item--2"
          />
          <img
            className='About-EnteringBlack-item--3 absolute top-[30%] left-[-35%] scale-[0.75]'
            src={EnteringBlack}
            alt="EnteringBlack-item--3"
          />
          <img
            className='About-EnteringBlack-item--4 absolute right-[-30%] bottom-[10%] scale-[0.6] z-[6]'
            src={EnteringBlue}
            alt="EnteringBlack-item--4"
          />

          <img
            className='About-EnteringBlue-item--1 absolute top-[-30%] right-[-20%] scale-[0.85]'
            src={EnteringBlack}
            alt="EnteringBlue-item--1"
          />
          <img
            className='About-EnteringBlue-item--2 absolute right-[-10%] bottom-[-30%] scale-[0.6] z-[7]'
            src={EnteringBlack}
            alt="EnteringBlue-item--2"
          />
          <img
            className='About-EnteringBlue-item--3 absolute left-[-20%] top-[0%] scale-[0.55]'
            src={EnteringBlue}
            alt="EnteringBlue-item--3"
          />
        </div>
      </div>
    </>
  )
}

export default HomeAboutEntering
